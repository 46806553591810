@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

* {
  outline: none;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

a,
span {
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

p,
ul {
  margin: 0;
  padding: 0;
}

.landing-page {
  background-image: url(../Assets/imgs/desktop-bg.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  padding: 20px 0;
  min-height: 100vh;
}
p.error {
  color: red !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  margin-top: 10px !important;
  text-align: center !important;
}
.disable-btn {
  opacity: 0.4 !important;
  cursor: default !important;
}

.landing-page .content {
  margin: 0px auto;
  max-width: 375px;
}

.landing-page .content .logo {
  margin-bottom: 25px;
  width: 55px;
}

.landing-page .content .main-title {
  font-size: 22px;
  color: #fff;
  line-height: 30px;
  margin-bottom: 20px;
}

.landing-page .content .main-img {
  width: 96%;
}
.landing-page .content span {
  font-size: 36px;
  font-weight: 700;
}

.landing-page .content .yellow {
  color: #fac128;
}

.landing-page .content .landing-page-img {
  margin-top: 25px;
}
.landing-page .form-content {
  background: linear-gradient(342deg, #09032C, #09032C, #102A52);
  padding: 48px 10px 20px 10px;
  margin-top: -60px;
}
.landing-page .content .day-free {
  margin-top: 15px;
}

.landing-page .content .btn-play {
  width: 80%;
  padding: 10px 15px;
  border-radius: 25px;
  border: none;
  background: linear-gradient(91deg, #00caff 0%, #dc94fe);
  font-size: 20px;
  font-weight: 700;
  font-family: montserrat;
  text-transform: uppercase;
  margin: 25px 0;
}

.landing-page .content h4 {
  font-size: 18px;
  color: #fff;
  margin: 0;
}

.landing-page .content .foot-text {
  font-size: 10px;
  font-weight: 400;
  color: #fff;
  line-height: 17px;
  width: 80%;
  margin: auto;
}

.landing-page .content a.term {
  font-size: 14px;
  color: #fff;
}

/* Mobile Responsive */

@media (max-width: 767px) {
  .landing-page {
    background-image: url(../Assets/imgs/mobile-bg.webp);
    background-position: bottom;
  }
}
@media (max-width: 450px) {
  .landing-page .content {
    max-width: 100%;
  }
}
